import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Layout from "../components/layout";

const StyledHome = styled.div`
  background: white url(/img/dirk.jpg) 100% 100% / 450px no-repeat;
  margin: -1rem;
  padding: 1rem 1rem 320px;
`;

export default ({ location }) => (
  <Layout location={location}>
    <Helmet>
      <title>Dirk's Jukebox</title>
    </Helmet>
    <StyledHome>
      <h1>Pagina niet gevonden</h1>
      <p>
        Uh-oh, deze pagina bestaat niet (meer). Gelukkig hebben we nog
        zo'n 4000 pagina's die <em>wel</em> bestaan!
      </p>
      <p>Klik op een van de links in de navigatie hierboven.</p>
    </StyledHome>
  </Layout>
);
